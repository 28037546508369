import React, { useEffect, useState } from 'react';
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { Wizard } from './components/Wizard'
import logo from "./assets/logo.png"
import './App.css';

function ErrorComponent(props: any) {
  return <p>An Error Occurred: {props?.error}</p>;
}

function LoadingComponent() {
  return <p>Authentifizieren...</p>;
}

export const graphScope = {
  scopes: ["openid", "profile"]
};

export const App: React.FunctionComponent = () => {
  const { accounts, instance } = useMsal();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  if (accounts.length > 0) {
    instance.setActiveAccount(accounts[0]);
  }
  const currentUserAccount = instance.getActiveAccount()?.username;

  useEffect(() => {
    const interval = setInterval(() => {
      window.location.reload(); 
    }, 30 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <React.Fragment>
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <a className="navbar-item" href="/">
            <img src={logo} width="112" height="28" alt="logo" />
          </a>

          <a role="button" className={`navbar-burger ${isOpen ? 'is-active' : ''}`} onClick={() => { setIsOpen(!isOpen) }} aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div id="navbarBasicExample" className={`navbar-menu ${isOpen ? 'is-active' : ''}`}>
          <div className="navbar-end">
            <div className="navbar-item">
              {currentUserAccount}
            </div>
            <div className="navbar-item">
              <div className="buttons">
                <a className="button is-light" onClick={() => instance.logoutRedirect()}>
                  Ausloggen
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
      
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={graphScope}
        errorComponent={ErrorComponent}
        loadingComponent={LoadingComponent}
      >
        <Wizard />
      </MsalAuthenticationTemplate>
    </React.Fragment >
  );
}
