import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { App } from './App';
import { MsalProvider } from '@azure/msal-react';
import { Configuration, PublicClientApplication } from '@azure/msal-browser';
import "bulma-list/css/bulma-list.css"
import { BrowserRouter } from 'react-router-dom';
import { reactPlugin } from "./AppInsights";
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';

const configuration: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_AUTH_CLIENTID as string,
    authority: process.env.REACT_APP_AUTH_AUTHORITY as string
  }
};

const pca = new PublicClientApplication(configuration);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <MsalProvider instance={pca}>
        <AppInsightsContext.Provider value={reactPlugin}>
          <App />
        </AppInsightsContext.Provider>
      </MsalProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
