import React, { useEffect, useMemo, useRef, useState } from 'react';


export const Viewport: React.FunctionComponent<{ children?: React.ReactNode }> = (props) => {
    const { children } = props;
    const [ isInViewPort, setIsInViewPort ] = useState<boolean>(false);

    const ref = useRef<HTMLDivElement>(null);
    const observer = useMemo(() =>
        new IntersectionObserver(([entry]) =>{
          if (!isInViewPort) {
            setIsInViewPort(entry.isIntersecting);
          }
        }),
      [isInViewPort],
    );

    useEffect(() => {
      if (ref && ref.current)
        observer.observe(ref.current);

      return () => {
        observer.disconnect();
      };
    }, [ref, observer]);

    return <div ref={ref}>{isInViewPort && (<React.Fragment>{children}</React.Fragment>)}</div>;
};
