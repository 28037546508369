import React, {useState } from "react";
import { IGroup } from "../../../services/AppService"
import appService from '../../../services/AppService'
import { GroupSearchInput } from "./GroupSearchInput"
import { useMsal } from "@azure/msal-react";

export const GroupSearch : React.FunctionComponent<{ onSelectedGroup: (group: IGroup) => void, recentGroups: IGroup[] }> = (props) => {
    const [groups, setGroups] = useState<IGroup[]>([]);
    const { instance } = useMsal();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { onSelectedGroup, recentGroups } = props;

    const searchGroups = async (name: string): Promise<void> => {
        setIsLoading(true);
        try {
            setGroups(await appService.searchGroups(name, instance));
        }
        finally {
            setIsLoading(false);
        }
      }
    
    return (
        <React.Fragment>
            <GroupSearchInput searchGroups={searchGroups} />
            {isLoading ? (
                <div className="notification">
                    Suchen...
                </div>
            ) : (
                <React.Fragment>
                    {groups.length === 0 ? (
                        <div className="notification">
                            Keine Ergebnisse vorhanden. Bitte die Suchergebnisse verfeinern...
                        </div>
                    ) : (
                        <div className="list">
                            {groups.map(((group, index) => {
                                return <div key={index} className="list-item pointer" onClick={(e) => { e.stopPropagation(); onSelectedGroup(group); }}>
                                    <div className="list-item-content">
                                        {group.displayName}
                                    </div>
                                </div>
                            }))}
                        </div>
                    )}
                </React.Fragment>
            )}
            {recentGroups.length > 0 && (
                <div>
                    <h3 className="recentTitle">Zuletzt ausgewählte Baustellen</h3>
                    <ul>
                        {recentGroups.map(group => <li className="list-item pointer" key={group.id} onClick={(e) => { e.stopPropagation(); onSelectedGroup(group); }}>{group.displayName}</li>)}
                    </ul>    
                </div>
            )}
        </React.Fragment>
    );
};