import React from "react";
import { IUploadProgress, UploadStatus } from "../../Wizard"

const getNotificationColor = (status: UploadStatus) => {
    if (status === UploadStatus.NotStarted) {
        return "";
    }
    if (status === UploadStatus.InProgress) {
        return "is-warning";
    }
    if (status === UploadStatus.Failure) {
        return "is-error";
    }
    return "is-success";
}

export const UploadProgress : React.FunctionComponent<{ fileUploadStatus: IUploadProgress[] }> = (props) => {
    const { fileUploadStatus } = props;

    return (
        <React.Fragment>
            {fileUploadStatus.map((status, index) => {
                const result = getNotificationColor(status.status);
                return <div key={index + result} className={`notification ${result}`}>
                    {status.message}
                </div>
            })}
        </React.Fragment>
    );
};