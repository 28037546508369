import { ApplicationInsights, DistributedTracingModes } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

export const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: "fbd4fbe1-00c2-405a-af36-fe9f36f84864",
        maxBatchInterval: 500,
        disableFetchTracking: false,
        enableAutoRouteTracking: true,
        extensions: [reactPlugin],
        enableCorsCorrelation: true,
        distributedTracingMode: DistributedTracingModes.W3C
    }
});
appInsights.loadAppInsights();