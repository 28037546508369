import React, {useState, useEffect, useRef} from "react";

export const GroupSearchInput : React.FunctionComponent<{ searchGroups: (name: string) => void}> = (props) => {
    const filterRef = useRef(); // use ref to call the API call all time except first time
    const [serpQuery, setSerpQuery] = useState('');
    const { searchGroups } = props;

    useEffect(() => {
        let delayTimeOutFunction : any;

        if(!filterRef.current) {
            (filterRef as any).current = true;

        } else { // componentDidMount equivalent
            delayTimeOutFunction = setTimeout(() => {
                searchGroups(serpQuery);
            }, 700); // denounce delay
        }
        return () => clearTimeout(delayTimeOutFunction);
    }, [serpQuery]);

    return (
        <input type="text" autoFocus key="searchGroup" placeholder="Baustelle suchen..." className="input searchManage" value={serpQuery} onChange={e => setSerpQuery(e.target.value)}  />
    );
};