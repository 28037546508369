import React, { useState } from "react";
import { IDriveItem } from "../../../services/AppService";
import appService from '../../../services/AppService'
import logo from "../../../assets/preview.png"
import { FaDownload } from "react-icons/fa";
import { useMsal } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { Viewport } from "../../ViewPort";

const downloadImage = (driveId: string, itemId: string, name: string, instance: IPublicClientApplication) : Promise<void> => {
    return new Promise<void>(async (resolve) => {
        appService.downloadImage(driveId, itemId, instance).then((blobObject) => {
            const objectUrl = window.URL.createObjectURL(blobObject as Blob);
            const anchor = document.createElement("a");
            anchor.style.display = "none";
            anchor.href = objectUrl;
            anchor.download = name;
            document.body.appendChild(anchor);
            anchor.click();
            //window.URL.revokeObjectURL(objectUrl);
        }).catch(() => {
            alert("Das Bild konnte nicht heruntergeladen werden");
        }).finally(() => {
            resolve();
        });
    });
}

export const PictureDetails : React.FunctionComponent<{ driveItem: IDriveItem }> = (props) => {
    const { driveItem } = props;
    const { instance } = useMsal();
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    let thumbNail = logo;
    if (driveItem.thumbnails !== undefined && driveItem.thumbnails.length > 0)
    {
        thumbNail = driveItem.thumbnails[0].large?.url ?? logo;
    }
    
    return <figure className="column is-3">
                 <div className="imageWrap">
                    {isLoading && "Wird geladen..." }
                    <Viewport>
                        <img alt="preview"
                                src={thumbNail}
                                onLoad={() => setIsLoading(false)} />
                    </Viewport>
                </div>
                {!isLoading && (
                    <React.Fragment>
                        <button className="button is-fullwidth" disabled={isDownloading} onClick={(e)=> {
                            e.preventDefault();
                            const driveItemId = driveItem?.parentReference?.driveId; 
                            const id = driveItem.id;
                            if (id && driveItemId) {
                                setIsDownloading(true);
                                downloadImage(driveItemId, id, driveItem?.name ?? "bild", instance).finally(() => setIsDownloading(false));
                            }
                        }}>
                            <FaDownload />&nbsp;&nbsp;{isDownloading ? "Wird heruntergeladen" : "Herunterladen"}
                        </button>
                        <p>{driveItem?.listItem?.fields?.additionalData?.beschreibung}</p>
                        <small>{new Date(driveItem.createdDateTime).toLocaleString()} ({driveItem.createdBy?.user?.displayName})</small>
                    </React.Fragment>
                )}    
            </figure>
};