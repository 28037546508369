import React from "react";
import { FaBackward } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export const Step4Action : React.FunctionComponent<{ wizardStep: number }> = (props) => {
    const { wizardStep } = props;
    const navigate = useNavigate();

    if (wizardStep < 4) {
        return <React.Fragment />
    }

    return (
        <div className="columns is-gapless">
            <div className="column is-3">
                <a className="button is-multiline is-large is-fullwidth"  onClick={() => navigate(0)}>
                    <FaBackward />
                    <span>Zurück zum Start</span>
                </a>
            </div>
        </div>
    );
};