import { IPublicClientApplication } from "@azure/msal-browser"

export interface IGroup {
    id: string,
    displayName: string,
    mailNickname: string
}

export interface IDriveItem {
    size: number
    createdBy: {
      user: {
        displayName: string,
       },
    },
    createdDateTime: string,
    name: string,
    parentReference: {
      driveId: string,
      id: string,
      path: string
    },
    listItem: {
        fields: {
            additionalData: any
        }
    },
    thumbnails: IThumbnail[]
    id: string
}

export interface IThumbnail {
    large: {
        url: string,
    }
    medium: {
        url: string
    },
    small: {
        url: string
    }
}

const getToken = async (instance: IPublicClientApplication) : Promise<string> => {
    const account = instance.getActiveAccount();
    if (!account) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    const response = await instance.acquireTokenSilent({
        ...{
          scopes: [process.env.REACT_APP_API_SCOPE as string]
        },
        account: account
    });

    return response.accessToken;
}

class AppService {
    private apiEndPoint: string = process.env.REACT_APP_API_ENDPOINT as string;
    
    public searchGroups = async (name: string, instance: IPublicClientApplication): Promise<IGroup[]> => {
        return new Promise<IGroup[]>(async (resolve, reject) => {
            try {
                const token = await getToken(instance)
                const headers = new Headers();
                headers.append("Authorization", `Bearer ${token}`);
                const results = await fetch(`${this.apiEndPoint}/api/search?name="${name}"`, {
                    method: "GET",
                    headers: headers
                });

                if (results.ok) {
                    resolve((await results.json()) as IGroup[]);
                }
                else {
                    reject([]);
                }
            } catch (exception) {
              console.error(exception);
              reject([]);
            }}
        );
    };

    public getPictures = async (id: string, instance: IPublicClientApplication): Promise<IDriveItem[]> => {
        return new Promise<IDriveItem[]>(async (resolve, reject) => {
            try {
                const token = await getToken(instance)
                const headers = new Headers();
                headers.append("Authorization", `Bearer ${token}`);
                const results = await fetch(`${this.apiEndPoint}/api/getPictures/${id}`, {
                    method: "GET",
                    headers: headers
                });

                if (results.ok) {
                    resolve((await results.json()) as IDriveItem[]);
                }
                else {
                    reject([]);
                }
            } catch (exception) {
              console.error(exception);
              reject([]);
            }}
        );
    };

    public downloadImage = async (driveId: string, itemId: string, instance: IPublicClientApplication): Promise<Blob | void> => {
        return new Promise<Blob | void>(async (resolve, reject) => {
            try {
                const token = await getToken(instance)
                const headers = new Headers();
                headers.append("Authorization", `Bearer ${token}`);
                const results = await fetch(`${this.apiEndPoint}/api/getPicture/${driveId}/${itemId}`, {
                    method: "GET",
                    headers: headers
                });

                if (results.ok) {
                    resolve(await results.blob());
                }
                else {
                    reject();
                }
            } catch (exception) {
              console.error(exception);
              reject();
            }}
        );
    };

    public uploadImage = async (file: Blob, groupId: string, lat: number, lng: number, description: string, instance: IPublicClientApplication, username?: string): Promise<boolean> => {
        return new Promise<boolean>(async (resolve, reject) => {
            try {
                const token = await getToken(instance)
                const headers = new Headers();
                headers.append("Authorization", `Bearer ${token}`);
                const formData = new FormData();
                formData.append("file", file);
                formData.append("groupId", groupId)
                formData.append("lat", lat.toString())
                formData.append("lng", lng.toString())
                formData.append("beschreibung", description.toString())
                if (username)
                    formData.append("username", username);

                let options: RequestInit = {
                    method: "POST",
                    body: formData,
                    headers: headers
                };
                let results = await fetch(`${this.apiEndPoint}/api/upload`, options);

                if (results.ok) {
                    resolve(true);
                }
                else {
                    reject(false);
                }
            } catch (exception) {
              console.error(exception);
              reject(false);
            }}
        );
    };
};

export default new AppService();
  