import React from "react";
import { FaBackward } from "react-icons/fa";

export const Step2BackAction : React.FunctionComponent<{ backToStep1: (e: any) => void }> = (props) => {
    const { backToStep1 } = props;

    return (
        <a className="button is-multiline is-fullwidth" onClick={backToStep1}>
            <FaBackward />
            <span>Zurück</span>
        </a>
    );
};