import React from "react";
import { FaUpload, FaBackward } from "react-icons/fa";

export const Step2Action : React.FunctionComponent<{ files: any, backToStep1: (e: any) => void, handleSubmit: (e: any) => Promise<void> }> = (props) => {
    const { files, backToStep1, handleSubmit } = props;

    if (files.length === 0) 
        return <React.Fragment />

    return (
        <div className="columns is-gapless">
            <div className="column">
                <a className="button is-link is-primary is-multiline is-large is-fullwidth" onClick={handleSubmit}>
                    <FaUpload />
                    <span>Jetzt hochladen!</span>
                </a>   
            </div>
            <div className="column is-3">
                <a className="button is-multiline is-large is-fullwidth" onClick={backToStep1}>
                    <FaBackward />
                    <span>Zurück</span>
                </a>
            </div>
        </div>
    );
};