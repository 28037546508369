import React, { useEffect, useState } from "react";
import { IDriveItem } from "../../../services/AppService";
import appService from '../../../services/AppService'
import { PictureDetails } from "./PictureDetails";
import { useMsal } from "@azure/msal-react";

interface MonthObject {
    key: string;
    month: number;
    year: number;
    description: string;
}

const getAvailableMonths = (data: IDriveItem[]) => {
    const monthsSet = new Set<string>();
    const monthsArray: MonthObject[] = [];

    data.forEach(item => {
        var date = new Date(item.createdDateTime);
        const month = date.getMonth() + 1; // Adding 1 because getMonth returns 0-indexed value
        const year = date.getFullYear();
        const description = date.toLocaleString('de-DE', { month: 'long' }) + ' ' + year;
        const key = `${month}-${year}`;

        if (!monthsSet.has(key)) {
            monthsSet.add(key);
            monthsArray.push({ key, month, year, description });
        }
    });

    // Sort array of objects by year and month
    monthsArray.sort((a, b) => {
        if (a.year !== b.year) {
            return b.year - a.year; // Sort by year descending
        } else {
            return b.month - a.month; // If years are equal, sort by month descending
        }
    });

    return monthsArray;
}

const filterItems = (driveItems: IDriveItem[], selectedMonth: MonthObject | null) => {
    if (selectedMonth == null)
        return driveItems;

    const filtered = driveItems.filter(item => {
        const date = new Date(item.createdDateTime);
        return date.getMonth() + 1 === selectedMonth.month && date.getFullYear() === selectedMonth.year;
    });
    return filtered;
};

export const PicturesContainer : React.FunctionComponent<{ groupId?: string }> = (props) => {
    const { groupId } = props;
    const { instance } = useMsal();
    const [driveItems, setDriveItems] = useState<IDriveItem[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [selectedMonth, setSelectedMonth] = useState<MonthObject | null>(null);

    useEffect(() => {
        if (!groupId || !instance)
            return;

        appService.getPictures(groupId, instance).then(p=> setDriveItems(p)).finally(() => setIsLoading(false));
    }, [groupId, instance])

    if (isLoading) {
        return <div className="notification">
            Bitte warten...
        </div>
    }

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedIndex = event.target.value;
        setSelectedMonth(months.find(p=> p.key === selectedIndex) ?? null);
    };

    const months = getAvailableMonths(driveItems);
    const filteredItems = filterItems(driveItems, selectedMonth);
    
    return (
        <React.Fragment>
            {months.length > 2 && (
                <div>
                    <select className="select mt-4" value={selectedMonth ? selectedMonth.key : ''} onChange={handleChange}>
                        <option value="">Alle anzeigen</option>
                        {months.map((month, index) => (
                            <option key={month.key} value={month.key}>
                                {month.description}
                            </option>
                        ))}
                    </select>
                </div>
            )}
            <div className="mt-2 columns is-multiline">
                {filteredItems.length === 0 && (
                    <div className="column">Keine Bilder gefunden</div>
                )}
                {filteredItems.map((item)=> (
                    <PictureDetails key={item.id} driveItem={item} />
                ))}
            </div>
        </React.Fragment>
    );
};