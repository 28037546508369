import React, { useState } from "react";
import { PictureSelection } from "./PictureSelection"
import { Step2Action } from "./Step2Action";
import { Step2BackAction } from "./Step2BackAction";
import { FaUpload, FaList } from "react-icons/fa";
import { PicturesContainer } from "./PicturesContainer";

export const Step2 : React.FunctionComponent<{ files: any, groupId?: string, backToStep1: (e: any) => void, handleSubmit: (e: any) => Promise<void>, setFiles: React.Dispatch<any> }> = (props) => {
    const { files, backToStep1, handleSubmit, setFiles, groupId } = props;
    const [isUploadPictures, setIsUploadPictures] = useState<boolean>(false);
    const [isDownloadPictures, setIsDownloadPictures] = useState<boolean>(false);

    if (!isDownloadPictures && !isUploadPictures) {
        return <div className="columns is-gapless">
            <div className="column">
                <a className="button is-multiline is-large is-fullwidth" onClick={(e) => { e.preventDefault(); setIsUploadPictures(true); setIsDownloadPictures(false); }}>
                    <FaUpload />
                    <div>Hochladen</div>
                </a>   
            </div>
            <div className="column">
            <a className="button is-multiline is-large is-fullwidth" onClick={(e) => { e.preventDefault(); setIsUploadPictures(false); setIsDownloadPictures(true); }}>
                <FaList />
                <div>Ansehen</div>
            </a>
            </div>
        </div>
    }

    if (isUploadPictures) {
        return <React.Fragment>
            <Step2Action files={files} handleSubmit={handleSubmit} backToStep1={backToStep1} />
            <PictureSelection files={files} setFiles={setFiles} backToStep1={backToStep1} />
            <Step2Action files={files} handleSubmit={handleSubmit} backToStep1={backToStep1} />
        </React.Fragment>
    }
    
    return (
        <React.Fragment>
            <Step2BackAction backToStep1={backToStep1} />
            <PicturesContainer groupId={groupId} />
            <Step2BackAction backToStep1={backToStep1} />
        </React.Fragment>
    );
};