import React, {useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import ios1 from "../../../assets/settings/iossettings1.png"
import ios2 from "../../../assets/settings/iossettings2.png"
import ios3 from "../../../assets/settings/iossettings3.png"

enum BrowserType {
    iOS,
    Android,
    Unknown
}

export const LocationRequest : React.FunctionComponent<{ onSetLocation: (latitude: number, longitude: number) => void }> = (props) => {
    const { onSetLocation } = props;
    const [locationNotLoaded, setLocationNotLoaded] = useState<boolean>(false);
    const history = useNavigate();

    const getLocation = () => {
        if (!navigator.geolocation) {
          setLocationNotLoaded(true);
        } else {
            navigator.geolocation.getCurrentPosition((position) => {
                onSetLocation(position.coords.latitude, position.coords.longitude);
          }, () => {
            setLocationNotLoaded(true);
          });
        }
    }

    useEffect(() => {
        getLocation();
    }, [])

    const reload = (e: any) => {
        e.preventDefault();
        history(0);
    }

    const getMobileOperatingSystem = () => {
        var userAgent = navigator.userAgent || navigator.vendor || (navigator as any)?.opera;
    
        if (/android/i.test(userAgent)) {
            return BrowserType.Android;
        }
    
        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any)?.MSStream) {
            return BrowserType.iOS;
        }
    
        return BrowserType.Unknown;
    }

    const device: BrowserType = getMobileOperatingSystem();

    if (locationNotLoaded) {
        return <React.Fragment>
            <section className="hero">
                <div className="hero-body">
                    <p className="title">
                        Standortzugriff nicht erteilt!
                    </p>
                    <p className="subtitle">
                        Die Anwendung kann nur mit aktiven Standortzugriff genutzt werden.
                    </p>
                    <button className="button is-fullwidth is-primary" onClick={reload}>Seite neu laden</button>
                </div>
            </section>
            {device !== BrowserType.Android && (
                <div className="has-text-centered">
                    <h2 className="title">Anleitung für iPhone oder iPad ändern</h2>
                    <img src={ios1} alt="logo" />
                    <img src={ios2} alt="logo" />
                    <img src={ios3} alt="logo" />
                </div>
            )}
            <br />
            {device !== BrowserType.iOS && (
                <div className="has-text-centered">
                    <h2 className="title">Anleitung für Android ändern</h2>
                    <img src={ios1} alt="logo" />
                    <img src={ios2} alt="logo" />
                    <img src={ios3} alt="logo" />
                </div>
            )}
            
        </React.Fragment>
    }
    
    return (<React.Fragment />);
};
